<script>
import Layout from "../../layouts/main";
// import ReportStatus from "./wallet-chart.vue";
import { pieChart } from "./data-apex";
import EasyDataTable from "vue3-easy-data-table";
import "vue3-easy-data-table/dist/style.css";
import axios from 'axios';

import base_domain from '../../myvars'
axios.defaults.baseURL = base_domain

export default {
  components: {
    Layout,
    EasyDataTable,
    // ReportStatus
  },
  data() {
    return {
      assignPrsn: 'Default AssignPrsn', // Default value of assignPrsn
      assignPrsn2: [], // Your array of options
      selectedOption: '', // Selected option (initially set to assignPrsn)
      pieChart: pieChart,
      searchValueTable: "",
      selectFilterSort: null,
      headers: [
        // { text: "Applicant Number", value: "applicantNum" },
        { text: "#ApplicantId", value: "applicantId" },        
        { text: "Applicant Name", value: "applicantName" },
        { text: "Assign", value: "assignPrsn" },
        { text: "Date", value: "logdate" },
        { text: "Status", value: "statusLog" },
        { text: "Action", value: "actionBtn" },
        { text: "Is Verified", value: "toggleBtn" },
      ],
      items: [],
      arrItem: [],
    };
  },
  mounted() {
    this.showdata();
  },
  created() {
    this.arrItem = this.items;
  },
  methods: {
    updateAssignPrsn() {
      const selectedValue = event.target.value;
      axios.post(`/applications/change_staff/`, {
            app_data: selectedValue,
          })
          .then((response) => {
            // Handle the response
            console.log("Error sending email:", response);
          })
          .catch((error) => {
            // Handle any errors
            console.error("Error sending email:", error);
          });
    },
    filterStatus() {
      if (this.selectFilterSort == null) {
        this.arrItem = this.items;
      } else {
        this.arrItem = this.items.filter((item) => {
          return item.statusLog == this.selectFilterSort;
        });
      }
    },
    showdata(){
        let arrList =[]
        const accessToken = localStorage.getItem("accessToken");
        axios.get("/applications/all_application_details/", {
                params: {
                    access_token: accessToken
                }
            })
            .then(response => {
            
            const user_data = response.data;
            console.log('this is for yes check',user_data)
             let responseList = response.data['all_details'];
                responseList.forEach(item => {
                console.log('this i',item.status)
                let newItem = {
                    applicantId:item.id,
                    applicantName: `${item.section_a1 && item.section_a1.first_name || 'N/A'} ${item.section_a1 && item.section_a1.sur_name || ''}`,
                    // applicantNum: item.application_no,
                    assignPrsn:item.rev,
                    assignPrsn2:item.rev2,
                    assignPrsn3:item.id,
                    dateLog:item.created_on,
                    statusLog:item.status,
                    actionBtnUrl:`/single_applicant/${item.id}`,
                    toggleBtnUrl: `${item.id}`,
                };
                // Push the transformed item to the existing array
                arrList.push(newItem);
                });
                // console.log("NEW ARRAY DATa",arrList);
                this.arrItem = arrList
            })
            .catch(error => {
            console.error(error);
            });
        },
        toggleCheckbox(toggleBtnUrl, event) 
        {
        // this.callApi(toggleBtnUrl);
        this.checkbox = !this.checkbox;
        console.log(toggleBtnUrl, this.checkbox, localStorage.getItem("accessToken"), event.target.checked)
        axios.post("/applications/toggle_verify/", { aid: toggleBtnUrl, verifyit:event.target.checked, tn: localStorage.getItem("accessToken"), })
          .then((response) => {
            if (response.data.status == "SUCCESS") {
              const indexToUpdate = this.arrItem.findIndex(item => item.applicantId === parseInt(response.data.aid));
              console.log("status____", response.data.status, indexToUpdate, response.data.newstatus);
              console.log(this.arrItem[indexToUpdate])
              if (indexToUpdate != -1) {   this.arrItem[indexToUpdate].statusLog = response.data.newstatus;   }
              console.log(this.arrItem[indexToUpdate])
            }
            // window.location.reload();
          })
          .catch((error) => {
            console.error(error);
          });
        },
  },
  
};
</script>

<template>
  <Layout>
    <main class="py-4">
      <div class="row mb-3">
        <div class="col">
          <h2 class="font-size-22 text-theme-dark">All Applications</h2>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <div class="row mb-3">
            <div class="col"></div>
            <div class="col-lg-6 mt-3 mt-md-0">
              <div class="row">
                <div class="col-md-5">
                  <select
                    class="form-select form-control selectCustomSize"
                    v-model="selectFilterSort"
                    name="favouriteSport"
                    @change="filterStatus"
                  >
                    <option selected :value="null">All Status</option>
                    <option value="submitted and under review">
                      Submitted and Under Review
                    </option>
                    <option value="incomplete">Incomplete</option>
                    <option value="approved">Approved</option>
                    <option value="rejected">Rejected</option>
                  </select>
                </div>
                <div class="col mt-3 mt-md-0">
                  <div class="groupSearchTop">
                    <input
                      type="text"
                      v-model="searchValueTable"
                      placeholder="Search..."
                      class="customInputForm form-control"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <EasyDataTable
            :headers="headers"
            :items="arrItem"
            :search-value="searchValueTable"
            border-cell
            theme-color="#df6a0d"
            :rows-per-page="10"
            buttons-pagination
            show-index
            table-class-name="table-custom-style noWrapHeading"
          >
            <template #item-statusLog="{ statusLog }">
              <p
                class="badge badge-pill font-size-11"
                :class="{
                  'badge-soft-success': `${statusLog}` === 'approved',
                  'badge-soft-danger': `${statusLog}` === 'rejected',
                  'badge-soft-primary':
                    `${statusLog}` === 'inprogress' ||
                    `${statusLog}` === 'InReview',
                  'badge-soft-warning': `${statusLog}` === 'incomplete',
                  'badge-soft-dark': `${statusLog}` === 'submitted',
                }"
              >
                {{ statusLog }}
              </p>
            </template>
            <template #item-assignPrsn="{ assignPrsn, assignPrsn2,assignPrsn3 }">
              <select class="form-select" @change="updateAssignPrsn">
                <option selected disabled :value="assignPrsn">{{ assignPrsn }}</option>
                <template v-for="(option, index) in assignPrsn2" :key="index">
                  <option v-if="assignPrsn !== option.first_name + ' ' + option.last_name" :value="option.id+'/'+assignPrsn3">
                    {{ option.first_name }} {{ option.last_name }}
                  </option>
                </template>
              </select>
            </template>
            <template #item-logdate="{ dateLog, timeLog }">
              <p class="mb-0 text-nowrap fw-medium">{{ dateLog }}</p>
              <p class="mb-0 text-nowrap">{{ timeLog }}</p>
            </template>
            <template #item-actionBtn="{ actionBtnUrl }">
              <router-link
                :to="`${actionBtnUrl}`"
                class="btn btn-sm btn-theme-dark"
              >
                <i class="mdi mdi-eye"></i>
                View
              </router-link>
              <p class="mb-0 text-nowrap fw-medium">{{ dateLog }}</p>
              <p class="mb-0 text-nowrap">{{ timeLog }}</p>
            </template>
            <template #item-toggleBtn="{ toggleBtnUrl, statusLog }">
              <label class="switch" v-if="(statusLog=='submitted' || statusLog=='verify')">
                <input
                  type="checkbox"
                  class="checkBoxCstmStl"
                  :checked="(statusLog=='verify')"
                  @click="toggleCheckbox(toggleBtnUrl, $event)"
                />  
                <div class="slider round"></div>
              </label>
            </template>
            <!-- <template #item-appactivity="appactivity">
                    <p class="mb-0">{{appactivity}}</p>
                </template> -->
          </EasyDataTable>
        </div>
      </div>
    </main>
  </Layout>
</template>
<style scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.slider { 
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}
.checkBoxCstmStl {
  opacity: 0;
  visibility: hidden;
}
input:checked + .slider {
  background-color: #f48226;
}

input:focus + .slider {
  box-shadow: 0 0 1px #f48226;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
</style>

<style scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 40px; /* Adjust the width as needed */
  height: 20px; /* Adjust the height as needed */
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 20px; /* Adjust the border-radius as needed */
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px; /* Adjust the height of the circle as needed */
  width: 16px; /* Adjust the width of the circle as needed */
  left: 2px; /* Adjust the left offset as needed */
  bottom: 2px; /* Adjust the bottom offset as needed */
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(
    18px
  ); /* Adjust the translateX value as needed */
  -ms-transform: translateX(18px);
  transform: translateX(18px);
}
</style>
